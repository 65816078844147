import { CanColor, mixinColor } from '@angular/material';
import { Component, ElementRef } from '@angular/core';

export class AdfLinkBase {
  constructor(public _elementRef: ElementRef) { }
}
export const _AdfLinkMixinBase = mixinColor(AdfLinkBase, 'accent');

// tslint:disable:component-selector
@Component({
  selector: 'a[adf-link]',
  template: `<ng-content></ng-content>`,
  host: { 'class': 'adf-link' },
  inputs: ['color']
})
export class AdfLink extends _AdfLinkMixinBase implements CanColor {
  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
