import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { getDomTitle } from './../../title';

export type StaffName = {
  first: string,
  last: string
} | undefined;
export type Staff = {
  name: StaffName,
  title: string,
  bio: string
} | undefined;

@Component({
  selector: 'adf-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnDestroy, OnInit {
  staff: Staff[];
  staffSub: Subscription;

  constructor(
    private domTitle: Title,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.staffSub = this.http.get('./assets/staff.json').subscribe((response) => {
      this.staff = response['staff'];
    });
    this.domTitle.setTitle(getDomTitle('About'));
  }

  ngOnDestroy() { this.staffSub.unsubscribe(); }

}
