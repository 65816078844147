export type NavIcon = NavIconMat | NavIconFa | undefined;
export type NavIconFa = [string, string] | undefined;
export type NavIconMat = string | undefined;

export class NavItem {
  private _title: string;
  private _url: string;
  private _icon: NavIcon;
  private _isFa: boolean;

  constructor(title: string, url: string, icon?: NavIcon, isFa: boolean = false) {
    this._title = title;
    this._url = url;
    this._icon = icon;
    this._isFa = isFa;
  }

  public set title(title: string) { this._title = title; }
  public get title() { return this._title; }

  public set url(url: string) { this._url = url; }
  public get url() { return this._url; }

  public set icon(icon: NavIcon) { this._icon = icon; }
  public get icon() { return this._icon; }

  public set isFa(isFa: boolean) { this._isFa = isFa; }
  public get isFa() { return this._isFa; }
}
