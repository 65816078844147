import {
  Component,
  ElementRef,
  OnInit,
  Version,
  VERSION as NG_VERSION,
  ViewChild
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDrawer, ThemePalette } from '@angular/material';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NoFeatData, NoFeatDialog } from './dialogs';
import { FORMS_NAVS, NavObject, NERDY_NAVS, SITE_NAVS } from './navigation';
import { VERSION as ADF_VERSION } from './version';

/**
 * The root component for the application, is bootstrapped by `AppModule`.
 * @author Travis Jones
 * @version 2.1.0-alpha.1
 * @since 2.1.0-alpha.1
 */
@Component({
  selector: 'adf-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit {
  title: string;
  color: ThemePalette;

  ngVersion: Version;
  adfVersion: Version;

  formsnavs: NavObject;
  nerdynavs: NavObject;
  sitenavs: NavObject;

  isMobileView: boolean;
  mediaSub: Subscription;
  @ViewChild('drawer') drawer: MatDrawer;

  routerSub: Subscription;

  changelogCount: number;
  changelogSub: Subscription;

  knownBugsCount: number;
  knownBugsSub: Subscription;

  _lockDrawerOpen: boolean;
  _darkModeEnabled: boolean;

  constructor(
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private http: HttpClient,
    private media: MediaObserver,
    private router: Router
  ) { }

  ngOnInit() {
    this.title = 'Aperture Design';
    this.color = 'primary';

    this._lockDrawerOpen = false;

    this.ngVersion = NG_VERSION;
    this.adfVersion = ADF_VERSION;

    this.formsnavs = FORMS_NAVS;
    this.nerdynavs = NERDY_NAVS;
    this.sitenavs = SITE_NAVS;

    this.isMobileView = (this.media.isActive('xs') || this.media.isActive('sm'));
    this.mediaSub = this.media.media$.subscribe((change: MediaChange) => {
      this.isMobileView = (change.mqAlias === 'xs' || change.mqAlias === 'sm');
    });

    this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects === '/404') {
          this.title = 'Error 404';
          this.color = 'warn';
        } else {
          this.title = 'Aperture Design';
          this.color = 'primary';
        }
      }
    });

    this.changelogSub = this.http.get('./assets/changelog.json').subscribe((response) => {
      this.changelogCount = response['entries'].length;
    });
    this.knownBugsSub = this.http.get('./assets/known-bugs.json').subscribe((response) => {
      this.knownBugsCount = response['known-bugs'].length;
    });
  }

  enableDrawerLock(): void {
    this._lockDrawerOpen = true;
  }
  disableDrawerLock(): void {
    this._lockDrawerOpen = false;
  }
  toggleDrawerLock(): void {
    this._lockDrawerOpen === true ? this.disableDrawerLock() : this.enableDrawerLock();
  }

  enableDarkMode(): void {
    this._darkModeEnabled = true;
    this.elementRef.nativeElement.classList.add('dark-theme');
  }
  disableDarkMode(): void {
    this._darkModeEnabled = false;
    this.elementRef.nativeElement.classList.remove('dark-theme');
  }
  toggleDarkMode(): void {
    this._darkModeEnabled === true ? this.disableDarkMode() : this.enableDarkMode();
  }

  onLinkClick() {
    if (this.isMobileView) {
      this.drawer.close();
    }
  }

  openNoFeat(data: NoFeatData) {
    this.dialog.open(NoFeatDialog, {data: data});
  }

  openSearch() {
    this.openNoFeat({feature: 'search', inDev: false});
  }

}
