import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { getDomTitle } from './../../../title';

export type FormObject = {
  title: string,
  description: string,
  id: string,
  src: string
} | undefined;

@Component({
  selector: 'adf-forms-main',
  templateUrl: './forms-main.component.html'
})
export class FormsMainComponent implements OnInit {
  httpSub: Subscription;
  forms: FormObject[];

  constructor(
    private http: HttpClient,
    private domTitle: Title
  ) { }

  ngOnInit() {
    this.domTitle.setTitle(getDomTitle('Forms'));
    this.httpSub = this.http.get('./assets/forms.json').subscribe((response) => {
      this.forms = response['forms'];
    });
  }
}
