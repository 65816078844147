import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from './../shared';

import { NoFeatDialog } from './no-feat';

const DIALOGS = [
  NoFeatDialog
];

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: DIALOGS,
  entryComponents: DIALOGS,
  exports: DIALOGS
})
export class DialogsModule { }
