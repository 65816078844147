import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  AboutComponent,
  ChangelogComponent,
  ErrorComponent,
  FormsComponent,
  formsRoutes,
  PortfolioComponent,
  portfolioRoutes,
  GamesComponent,
  gamesRoutes,
  HomeComponent,
  KnownBugsComponent
} from './views';

/**
 * The core routes for the application.
 * @since 2.1.0-alpha.1
 */
const routes: Routes = [
  // initial redirect
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  // main pages
  { path: 'home', component: HomeComponent },
  { path: 'portfolio', component: PortfolioComponent, children: portfolioRoutes },
  { path: 'forms', component: FormsComponent, children: formsRoutes },
  { path: 'about', component: AboutComponent },

  // nerdy pages
  { path: 'changelog', component: ChangelogComponent },
  { path: 'known-bugs', component: KnownBugsComponent },
  { path: 'games', component: GamesComponent, children: gamesRoutes },

  // error page & error redirect
  { path: '404', component: ErrorComponent },
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

/**
 * The primary routing module for the application. This MUST be imported before
 * any other routing modules, otherwise the routing will not function properly.
 * @author Travis Jones
 * @version 2.1.0-alpha.1
 * @since 2.1.0-alpha.1
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
