import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { AdfLayoutModule } from 'projects/aperture-design-framework/src';
import { SharedModule } from './../../shared';

import { GamesComponent } from './games.component';
import { GamesMainComponent } from './games-main';
import { GamesHangmanComponent } from './games-hangman';

export const gamesRoutes: Routes = [
  { path: '', redirectTo: '/games/main', pathMatch: 'full' },
  { path: 'main', component: GamesMainComponent },
  { path: 'hangman', component: GamesHangmanComponent },
  { path: '**', redirectTo: '/games/main', pathMatch: 'full' }
];

@NgModule({
  imports: [
    AdfLayoutModule,
    CommonModule,
    RouterModule.forChild(gamesRoutes),
    SharedModule
  ],
  declarations: [
    GamesComponent,
    GamesHangmanComponent,
    GamesMainComponent
  ],
  exports: [
    // module exports
    RouterModule,

    // component exports
    GamesComponent,
    GamesHangmanComponent,
    GamesMainComponent
  ]
})
export class AppGamesModule { }
