import { NgModule } from '@angular/core';

// import Angular modules
import { BrowserModule } from '@angular/platform-browser';

// import & configure Font Awesome 5
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
library.add(fas, far, fal, fab);
dom.watch();

// import project modules
import { AdfLayoutModule } from 'projects/aperture-design-framework/src';

// import other modules
import { AppRoutingModule } from './app-routing.module';
import { DialogsModule } from './dialogs';
import { SharedModule } from './shared';

// import AppComponent
import { AppComponent } from './app.component';

// import view components
import {
  AboutComponent,
  AppFormsModule,
  AppGamesModule,
  AppPortfolioModule,
  ChangelogComponent,
  ErrorComponent,
  HomeComponent,
  KnownBugsComponent
} from './views';

/**
 * The primary application module, this bootstraps the `AppComponent`.
 * @author Travis Jones
 * @version 2.1.0-alpha.1
 * @since 2.1.0-alpha.1
 */
@NgModule({
  declarations: [
    AboutComponent,
    AppComponent,
    ChangelogComponent,
    ErrorComponent,
    HomeComponent,
    KnownBugsComponent
  ],
  imports: [
    // import AppRoutingModule first!
    AppRoutingModule,
    AppFormsModule,
    AppGamesModule,
    AppPortfolioModule,

    // other modules (list alphabetically)
    AdfLayoutModule,
    BrowserModule,
    DialogsModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
