import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { getDomTitle } from './../../title';

export type KnownBug = {
  title: string,
  description: string
} | undefined;

@Component({
  selector: 'adf-known-bugs',
  templateUrl: './known-bugs.component.html'
})
export class KnownBugsComponent implements OnInit {
  httpSub: Subscription;
  knownBugs: KnownBug[] = [];

  constructor(
    private http: HttpClient,
    private domTitle: Title
  ) { }

  ngOnInit() {
    this.httpSub = this.http.get('./assets/known-bugs.json').subscribe((response) => {
      const data = response['known-bugs'];
      if (data === undefined || data === null) {
        this.knownBugs = [];
      } else {
        this.knownBugs = data;
      }
    });
    this.domTitle.setTitle(getDomTitle('Known Bugs'));
  }
}
