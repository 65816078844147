import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { getDomTitle } from './../../../title';

@Component({
  selector: 'adf-games-hangmna',
  templateUrl: './games-hangman.component.html'
})
export class GamesHangmanComponent implements OnInit {
  constructor(
    private domTitle: Title
  ) { }

  ngOnInit() {
    this.domTitle.setTitle(getDomTitle('Games | Hangman'));
  }
}
