import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule, MediaObserver } from '@angular/flex-layout';

import { MaterialModule } from '../material';

const SHARED_MODULES = [
  BrowserAnimationsModule,
  FlexLayoutModule,
  MaterialModule
];

@NgModule({
  imports: SHARED_MODULES,
  exports: SHARED_MODULES,
  providers: [MediaObserver]
})
export class SharedModule { }
