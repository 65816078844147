import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { AdfLayoutModule } from 'projects/aperture-design-framework/src';
import { SharedModule } from './../../shared';

import { FormsComponent } from './forms.component';
import { FormsPageComponent } from './forms-page';
import { FormsMainComponent } from './forms-main';

export const formsRoutes: Routes = [
  { path: '', redirectTo: '/forms/main', pathMatch: 'full' },
  { path: 'main', component: FormsMainComponent },
  { path: ':id', component: FormsPageComponent },
  { path: '**', redirectTo: '/forms/main', pathMatch: 'full' }
];

@NgModule({
  imports: [
    AdfLayoutModule,
    CommonModule,
    SharedModule,
    RouterModule.forChild(formsRoutes)
  ],
  declarations: [
    FormsComponent,
    FormsPageComponent,
    FormsMainComponent
  ],
  exports: [
    // module exports
    RouterModule,

    // component exports
    FormsComponent,
    FormsPageComponent,
    FormsMainComponent
  ]
})
export class AppFormsModule { }
