import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { AdfLayoutModule } from 'projects/aperture-design-framework/src';
import { SharedModule } from './../../shared';

import { PortfolioComponent } from './portfolio.component';
import { PortfolioMainComponent } from './portfolio-main';

export const portfolioRoutes: Routes = [
  // initial redirect
  { path: '', redirectTo: '/portfolio/main', pathMatch: 'full' },
  { path: 'main', component: PortfolioMainComponent },
  { path: '**', redirectTo: '/portfolio/main', pathMatch: 'full' }
];

@NgModule({
  imports: [
    AdfLayoutModule,
    CommonModule,
    SharedModule,
    RouterModule.forChild(portfolioRoutes)
  ],
  declarations: [
    PortfolioComponent,
    PortfolioMainComponent
  ],
  exports: [
    // module exports
    RouterModule,

    // component exports
    PortfolioComponent,
    PortfolioMainComponent
  ]
})
export class AppPortfolioModule { }
