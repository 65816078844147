import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { getDomTitle } from './../../../title';

@Component({
  selector: 'adf-forms-page',
  template: `
    <iframe [src]="src" class="adf-form" frameborder="0" marginheight="0" marginwidth="0">
      Loading, please wait...
    </iframe>
  `,
  host: { 'class': 'adf-form-container' }
})
export class FormsPageComponent implements OnDestroy, OnInit {
  src: SafeResourceUrl;
  id: string;

  httpSub: Subscription;
  routeSub: Subscription;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private title: Title
  ) { }

  ngOnInit() {
    this.httpSub = this.http.get('./assets/forms.json').subscribe((response) => {
      const data = response['forms'];
      this.routeSub = this.route.paramMap.subscribe((params: ParamMap) => {
        this.id = params.get('id');
        console.log(this.id);
      });
      for (let i = 0; i < data.length; i++) {
        if (this.id === data[i].id) {
          const src = data[i].src;
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(src);
          this.title.setTitle(getDomTitle(data[i].title));
        }
      }
    });
  }

  ngOnDestroy() {
    this.httpSub.unsubscribe();
    this.routeSub.unsubscribe();
    this.src = undefined;
    this.id = undefined;
  }
}
