import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export type NoFeatData = {
  feature: string,
  inDev: boolean
} | undefined;

// tslint:disable:max-line-length
@Component({
  selector: 'adf-no-feat',
  template: `
    <h1 mat-dialog-title>{{ data.feature | titlecase }} Unavailable</h1>
    <div mat-dialog-content>
      <p>{{ data.feature | titlecase }} is currently unavailable and is{{ data.inDev ? '':' not' }} in development{{ data.inDev ? '':' yet'}}.</p>
      <p>Please check back later.</p>
    </div>
    <div mat-dialog-actions>
      <span fxFlex></span>
      <button color="accent" (click)="dialogRef.close()" mat-button>Okay</button>
    </div>
  `
})
export class NoFeatDialog {
  data: NoFeatData;

  constructor(
    public dialogRef: MatDialogRef<NoFeatDialog>,
    @Inject(MAT_DIALOG_DATA) data: NoFeatData
  ) {
    this.data = data;
  }
}
