import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'adf-figure',
  template: `<ng-content select="[adf-figimg], adf-figcaption, mat-divider"></ng-content>`,
  host: { 'class': 'adf-figure', 'role': 'figure' }
})
export class AdfFigure implements OnInit {
  @Input() elevation: number;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    if (this.elevation !== undefined && this.elevation !== null) {
      if (this.elevation >= 0 && this.elevation <= 24) {
        (this.elementRef.nativeElement as HTMLElement).classList.add(`mat-elevation-z${this.elevation}`);
      } else {
        console.error(`Elevation ${this.elevation} is out of bounds. Please enter a number between 0 and 24.`);
      }
    }
  }
}
