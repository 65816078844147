import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'adf-container',
  template: `<ng-content></ng-content>`,
  host: { 'class': 'adf-container' }
})
export class AdfContainer implements OnInit {
  @Input() maxWidth: string;
  @Input() maxWidthUnit: string;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    const num = parseInt(this.maxWidth, 10);
    if (this.maxWidthUnit === undefined) {
      this.maxWidthUnit = 'px';
    }
    if (this.maxWidth === undefined) {
      this.maxWidth = '960px';
    } else if (num !== null) {
      this.maxWidth = num + this.maxWidthUnit;
    }
    (this.elementRef.nativeElement as HTMLElement).style.maxWidth = this.maxWidth;
  }

}
