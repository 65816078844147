import { CanColor, mixinColor } from '@angular/material';
import { Component, ElementRef, Input } from '@angular/core';

export class AdfHeaderBase {
  constructor(public _elementRef: ElementRef) { }
}
export const _AdfHeaderMixinBase = mixinColor(AdfHeaderBase);

@Component({
  selector: 'adf-header',
  template: `
    <h1 *ngIf="head != undefined" class="adf-header-head">{{ head }}</h1>
    <p *ngIf="body != undefined" class="adf-header-body">{{ body }}</p>
  `,
  host: { 'class': 'adf-header', 'role': 'header' },
  inputs: ['color']
})
export class AdfHeader extends _AdfHeaderMixinBase implements CanColor {
  @Input() head: string;
  @Input() body: string;

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
