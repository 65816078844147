import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from './../shared';

import { AdfButtonGroup } from './button-group';
import { AdfContainer } from './container';
import { AdfContent } from './content';
import { AdfFigcaption, AdfFigimg, AdfFigure } from './figure';
import { AdfFooter } from './footer';
import { AdfHeader } from './header';
import { AdfLayoutPadding } from './layout-padding';
import { AdfLink } from './link';
import { AdfReverseList } from './reverse-list';
import { AdfTruncate } from './truncate';

const LAYOUT = [
  AdfButtonGroup,
  AdfContainer,
  AdfContent,
  AdfFigcaption,
  AdfFigimg,
  AdfFigure,
  AdfFooter,
  AdfHeader,
  AdfLayoutPadding,
  AdfLink,
  AdfReverseList,
  AdfTruncate
];

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: LAYOUT,
  exports: LAYOUT
})
export class AdfLayoutModule { }
