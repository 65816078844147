import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { getDomTitle } from './../../title';

export type WipItem = {
  title: string,
  description: string,
  eta: string,
  url?: string,
  image?: { src: string, alt: string }
} | undefined;

/**
 * The home page view for the application.
 * @author Travis Jones
 * @version 2.1.0-alpha.1
 * @since 2.1.0-alpha.1
 */
@Component({
  selector: 'adf-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnDestroy, OnInit {
  projects: WipItem[];
  projectsSub: Subscription;

  constructor(
    private domTitle: Title,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.projectsSub = this.http.get('./assets/wip.json').subscribe((response) => {
      this.projects = response['projects'];
    });
    this.domTitle.setTitle(getDomTitle('Home'));
  }

  ngOnDestroy() { this.projectsSub.unsubscribe(); }

}
