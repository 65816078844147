import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { getDomTitle } from './../../../title';

@Component({
  selector: 'adf-portfolio-main',
  templateUrl: './portfolio-main.component.html'
})
export class PortfolioMainComponent implements OnInit {
  constructor(
    private domTitle: Title
  ) { }

  ngOnInit() {
    this.domTitle.setTitle(getDomTitle('Portfolio'));
  }
}
