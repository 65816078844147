import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { getDomTitle } from './../../../title';

@Component({
  selector: 'adf-games-main',
  templateUrl: './games-main.component.html'
})
export class GamesMainComponent implements OnInit {
  constructor(
    private domTitle: Title
  ) { }

  ngOnInit() {
    this.domTitle.setTitle(getDomTitle('Games'));
  }
}
