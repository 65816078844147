import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { getDomTitle } from './../../title';

@Component({
  selector: 'adf-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  constructor(
    private domTitle: Title
  ) { }

  ngOnInit() {
    this.domTitle.setTitle(getDomTitle('Error 404'));
  }
}
