import { NavItem, NavObject } from './public_api';

export const SITE_NAVS: NavObject = new NavObject(
  'Site Navigation', [
    new NavItem('Home', '/home', 'home'),
    new NavItem('Portfolio', '/portfolio', 'work'),
    new NavItem('Forms', '/forms', 'edit'),
    new NavItem('About', '/about', 'info')
  ]
);
