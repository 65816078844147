import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { getDomTitle } from './../../title';

export type ChangelogChangePriority = 'high' | 'normal' | 'low' | undefined;
export type ChangelogChange = {
  title: string,
  description: string,
  priority: ChangelogChangePriority
} | undefined;
export type ChangelogEntry = {
  title: string,
  description: string,
  version: string,
  date: string,
  changes: []
} | undefined;

@Component({
  selector: 'adf-changelog',
  templateUrl: './changelog.component.html'
})
export class ChangelogComponent implements OnDestroy, OnInit {
  entries: ChangelogEntry[];
  httpSub: Subscription;

  constructor(
    private domTitle: Title,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.httpSub = this.http.get('./assets/changelog.json').subscribe((response) => {
      this.entries = response['entries'];
    });
    this.domTitle.setTitle(getDomTitle('Changelog'));
  }

  ngOnDestroy() { this.httpSub.unsubscribe(); }

}
