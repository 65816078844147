import { CanColor, mixinColor } from '@angular/material';
import { Component, ElementRef } from '@angular/core';

export class AdfFooterBase {
  constructor(public _elementRef: ElementRef) { }
}
export const _AdfFooterMixinBase = mixinColor(AdfFooterBase);

@Component({
  selector: 'adf-footer',
  template: `<ng-content></ng-content>`,
  host: { 'class': 'adf-footer', 'role': 'footer' },
  inputs: ['color']
})
export class AdfFooter extends _AdfFooterMixinBase implements CanColor {
  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
