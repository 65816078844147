import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule, MediaObserver } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MaterialModule } from './../material';

const SHARED = [
  BrowserAnimationsModule,
  FlexLayoutModule,
  FontAwesomeModule,
  FormsModule,
  HttpClientModule,
  MaterialModule,
  ReactiveFormsModule
];

@NgModule({
  imports: SHARED,
  exports: SHARED,
  providers: [MediaObserver]
})
export class SharedModule { }
