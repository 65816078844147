import { NavItem } from './nav-item';

export class NavObject {
  private _header: string;
  private _navs: NavItem[];

  constructor(header: string, navs: NavItem[]) {
    this._header = header;
    this._navs = navs;
  }

  public set header(header: string) { this._header = header; }
  public get header() { return this._header; }

  public set navs(navs: NavItem[]) { this._navs = navs; }
  public get navs() { return this._navs; }
}
