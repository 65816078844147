import { Component, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'adf-forms',
  template: `<router-outlet></router-outlet>`,
  styles: [`
    :host { flex: 1 1 100%; }
  `]
})
export class FormsComponent implements OnInit {
  routerSub: Subscription;
  httpSub: Subscription;

  constructor(
    private _elementRef: ElementRef,
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    const element = (this._elementRef.nativeElement as HTMLElement);
    this.httpSub = this.http.get('./assets/forms.json').subscribe((response) => {
      const data = response['forms'];
      this.routerSub = this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          const url = val.urlAfterRedirects;
          let okayToStyle = false;
          for (let i = 0; i < data.length; i++) {
            const id = `/forms/${data[i].id}`;
            if (url === id) {
              okayToStyle = true;
            }
          }
          if (okayToStyle) {
            element.style.flex = '1 1 100%';
            element.style.overflow = 'hidden';
          } else {
            element.style.flex = 'none';
            element.style.overflow = 'auto';
          }
        }
      });
    });
  }
}
